/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDa1gFt-GhgbJ_4M16BKsfs_Zbn74K7PqQ",
  "appId": "1:457030368716:web:dd46a8c23c22077f193169",
  "authDomain": "schooldog-i-griffinspalding-ga.firebaseapp.com",
  "measurementId": "G-99VKD8DPEM",
  "messagingSenderId": "457030368716",
  "project": "schooldog-i-griffinspalding-ga",
  "projectId": "schooldog-i-griffinspalding-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-griffinspalding-ga.appspot.com"
}
